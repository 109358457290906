<template>
  <div>
    <h2 class="headline--md text-bold text-uppercase">infomation detail</h2>

    <div v-if="feed" class="box box--md box--white mt-16">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Email</p>
                <h2 class="headline--md mt-4">{{ feed.user.email }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Full Name</p>
                <h2 class="headline--md mt-4">{{ feed.user.fullName }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Wallet Address</p>
                <div class="clipboard-copy">
                  <h2 class="headline--md mt-4 clipboard-copy__text">
                    <span class="clipboard-copy__text-inner">{{ feed.address }}</span>
                    <copy-clipboard @on-copy="doCopy($event)" :external-link="`/etherscan-address/${feed.address}`" />
                  </h2>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Deposited Amount (TOKO)</p>
                <h2 class="headline--md mt-4">{{ feed.totalAmountDeposit | float_number_with_commas }}</h2>
              </div>
            </td>
            <td v-if="feed.user.blacklist">
              <p class="statistic__title text-warm">Black List</p>
              <h2 class="headline--md mt-4 text-uppercase text-bold text-error">
                {{ feed.user.blacklist | format_blacklist_type }}
              </h2>
            </td>
            <td v-else />
            <td />
          </tr>
        </tbody>
      </table>
    </div>

    <h2 class="headline--md text-bold text-uppercase mt-32">staking transactions</h2>
    <div class="box box--md box--white mt-16">
      <transactions v-if="transactions && transactions.data" :transactions="transactions.data" :isNewTab="true" />

      <div v-if="transactions" class="text-center mt-32">
        <p>{{ transactions.total | format_transaction_count_text }} in total</p>

        <pagination
          v-if="transactions.total > transactionParams.perPage"
          v-model="transactionParams.page"
          :page-count="transactions.totalPages || Math.ceil(transactions.total / transactionParams.perPage)"
          :page-range="5"
          :click-handler="clickPagerTransactionCallback"
        ></pagination>
      </div>
    </div>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import toggleLoading from '@/mixins/toggle-loading'
import copyClipboard from '@/mixins/copy-clipboard'
const CopyClipboard = () => import('@/components/CopyClipboard')
const Transactions = () => import('@/components/Transactions')
const Pagination = () => import('@/components/Pagination')

export default {
  data() {
    return {
      transactionParams: {
        page: 1,
        perPage: 10
      }
    }
  },

  mixins: [toggleLoading, copyClipboard],

  computed: {
    ...mapState('crowdfunding', ['feed']),

    ...mapState('transaction', ['transactions'])
  },

  components: {
    CopyClipboard,
    Transactions,
    Pagination
  },

  methods: {
    ...mapActions('crowdfunding', ['getCrowdfundingFeed']),

    ...mapMutations('crowdfunding', ['CLEAR_CROWDFUNDING_FEED']),

    ...mapActions('transaction', ['getTransactions']),

    ...mapMutations('transaction', ['CLEAR_TRANSACTIONS_DATA']),

    clickPagerTransactionCallback() {
      this.toggleLoading()
      this.getTransactions(this.getTransactionParams()).finally(() => {
        this.toggleLoading()
      })
    },

    getTransactionParams() {
      return {
        page: this.transactionParams.page,
        limit: this.transactionParams.perPage,
        userId: this.$route.params.id
      }
    }
  },

  async created() {
    this.CLEAR_CROWDFUNDING_FEED()
    this.CLEAR_TRANSACTIONS_DATA()
    try {
      await this.getCrowdfundingFeed(this.$route.params.id)
      await this.getTransactions(this.getTransactionParams())
    } catch (e) {
      this.toggleLoading()
      throw e
    }

    this.toggleLoading()
  }
}
</script>
